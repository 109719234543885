<template>
  <v-dialog v-model="model" max-width="700" scrollable persistent opacity="0.65">
    <v-card>
      <v-form @submit.prevent="onSubmit">
        <v-card-title class="text-center">
          <h4 v-if="title"> {{ title }}</h4>
          <h4 v-if="type==='confirmation'" class="justify-center"> Confirm</h4>
        </v-card-title>
        <v-divider class="mx-4" />
        <v-card-text>
          <div class="confirmation-container">
            <div v-if="type === 'confirmation' || type === 'confirmationTable'" class="mb-5" :class="['question-animation', { 'rotate-once': rotateAnimation }]">?</div>
            <div class="text-center">{{ questionContent }}</div>
            <reusable-generic-table
              v-if="type === 'confirmationTable' && properties.rows && properties.headers"
              :rows="properties.rows"
              :headers="properties.headers"
              :pagination="confirmationTablePagination"
              :loading="false"
              :show-select="false"
            />
          </div>
          <v-text-field
            v-if="type === 'text'"
            v-model="inputValue"
            :label="label"
            v-bind="inputValueAttrs"
          />
          <v-autocomplete
            v-if="type === 'select'"
            v-model="inputValue"
            :label="label"
            :items="items"
            v-bind="inputValueAttrs"
          />
          <div v-if="type==='progress'" class="text-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            />
            <h3>{{ messaage }}</h3>
          </div>
        </v-card-text>
        <v-card-actions v-if="type!=='progress'" class="d-flex">
          <div class="w-100 pr-1">
            <v-btn block color="red" variant="outlined" @click="cancel">{{ cancelText }}</v-btn>
          </div>
          <div class="w-100 pl-1">
            <v-btn block color="success" type="submit" variant="outlined">{{ confirmText }}</v-btn>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { z } from 'zod'
import { toTypedSchema } from '@vee-validate/zod'
import type { PromptProperties } from '~/types/Reusable'
import type { PaginationSettings } from '~/types/GenericTable'

const model = ref(false)
const properties = defineProps<PromptProperties<Record<string, never>>>()
const { $eventBus } = useNuxtApp()
const confirmationTablePagination = ref<PaginationSettings>({
  draw: 1,
  pageLength: 5,
  pageNumber: 1,
  searchHint: '',
  sortBy: [],
})

const rotateAnimation = ref(false)

onMounted(() => {
  model.value = true
  rotateAnimation.value = true
  setTimeout(() => rotateAnimation.value = false, 1000) // Reset after animation completes
})

const confirmText = computed(() => {
  if (properties.confirmText) {
    return properties.confirmText
  }
  return 'YES'
})

const cancelText = computed(() => {
  if (properties.cancelText) {
    return properties.cancelText
  }
  return 'CANCEL'
})
const questionContent = computed(() => properties.question)
const messaage = computed(() => properties.message)

const validationSchema = computed(() => {
  return z.object({
    inputValue: properties.rules ? properties.rules : z.string().optional(),
  })
})
const { defineField, handleSubmit, setFieldValue } = useForm({
  validationSchema: toTypedSchema(validationSchema.value),
  initialValues: {
    inputValue: properties.initialValue,
  },
})

const [inputValue, inputValueAttrs] = defineField('inputValue', VUETIFY_INPUT_CONFIG)

const onSubmit = handleSubmit((values) => {
  model.value = false
  $eventBus.emit('hide-prompt', {
    id: properties.id,
    value: properties.type === 'confirmation' || properties.type === 'confirmationTable' ? true : values.inputValue,
  })
})

const cancel = () => {
  model.value = false
  $eventBus.emit('hide-prompt', {
    id: properties.id,
    cancelled: true,
  })
}

watch(
  () => properties.id,
  (_n, _o) => {
    setFieldValue('inputValue', properties.initialValue)
  }
)
</script>

<style scoped>
@keyframes rotateInPlace {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.question-animation {
  font-size: 3rem;
  color: #3f51b5;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #3f51b5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate-once {
  animation: rotateInPlace 1s ease-in-out;
}
</style>
