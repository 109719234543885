import type { z } from 'zod'
import type { GrantDataSchema, RoleSchema, SecurityPolicySchema } from './AccountValidation'

export const AVAILABLE_GRANTS = [
  'SYSTEM',
  'MFA_REQUIRED',
  'APPLY_ACL',
  'RND',
  'EXPORT_TEMPLATES',
  'SECURITY', 'ACCOUNTS', 'ADF_FILES', 'DEALERS', 'SUBDEALERS', 'DOMAINS', 'BUFFERS', 'EXPORT_MONITOR',
  'FTP_ACCOUNTS', 'MAIL_ACCOUNTS', 'SUBSCRIPTION_PLANS', 'ACCESS_GROUPS', 'SUPPORT', 'VIRTUAL_DEVICES', 'STORYBLOK',
  'DEVICES', 'DEVICE_UPDATES', 'DEVICE_TAGS', 'DEVICE_INFO_EXTENDED', 'DEVICE_BLACKLIST', 'DEVICE_MAP', 'DEVICE_ASSIGNED_CLIENTS', 'DEVICE_EXPORT', 'DEVICE_CONTROL_PANEL',
] as const
  type GrantTuple = typeof AVAILABLE_GRANTS
export type Grant = GrantTuple[number]
export type GrantType = 'WRITE' | 'READ' | 'DENY'
export type GrantRequirements = 'ALL' | 'ANY'
export type GrantTypePriority = Record<GrantType, number>
export const GrantPriority: GrantTypePriority = {
  DENY: 0,
  READ: 1,
  WRITE: 2,
}
export type GrantCallback = () => boolean
export type GrantPermission = Record<GrantType, GrantCallback>
export type Grants = Record<Grant, GrantType>
export type Permissions = Record<Grant, GrantPermission>

export interface OptilinkGrantedAuthority {
  authority: `GRANT_${Grant}`
  type: GrantType
}

export interface ApiToken {
  createdAt: string
  expiresAt: string | null
  domain: string
  id: string
  user: string
  authorities: OptilinkGrantedAuthority[]
  serviceName: string
  remoteIp: string
}

export type GroupInput = {
  title: string
  value: Permission[]
}
export type PermissionGroupInput = {
  text: string
  value: PermissionGroup
  permissions: Permission[]
  availablePermissions: GroupInput[]
}
export type PermissionGroup = 'BUFFERS' | 'OPTIMABOX' | 'ADF' | 'DEVICES'
export type Permission = 'READ' | 'CREATE' | 'UPDATE' | 'DELETE'

export interface OptilinkApiToken extends ApiToken {
  rateLimit: number
  interval: number
  permissions: Record<PermissionGroup, Permission[]>
}

export interface LoginAsApiToken extends ApiToken {
  source: ApiToken
}

export type ApiTokenMetadata = {
  languageCode: string
  pcNumbers: number[]
  username: string
}

export type Role = z.infer<typeof RoleSchema>
export type SecurityPolicy = z.infer<typeof SecurityPolicySchema>
export type GrantData = z.infer<typeof GrantDataSchema>
