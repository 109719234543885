import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_date_2O0zXrGOlD7lQCQcANO3uzf6G2MHRHLiOJnA_6Try68 from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-date.js";
import vuetify_icons_uipcv7oAosTqHCLrDDbtENc9Xwt1PnnChKPXu5AVcjE from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/.nuxt/pwa-icons-plugin.ts";
import vuetify_no_client_hints_1iRQgYFRR7xfuMumrkN4UGvWFXdQAoiR_NXI9qrJALY from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import directives_Y6D8IjviQKB3EteSmgkh6nr8GGru_rC_DjhQ3lLa_GM from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/plugins/directives.ts";
import event_bus_ViUSoJuKW_GQ8BDIbjhUcCejLK_UaZMaFLZMM_zeOmo from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/plugins/event-bus.ts";
import pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  vuetify_date_2O0zXrGOlD7lQCQcANO3uzf6G2MHRHLiOJnA_6Try68,
  vuetify_icons_uipcv7oAosTqHCLrDDbtENc9Xwt1PnnChKPXu5AVcjE,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  vuetify_no_client_hints_1iRQgYFRR7xfuMumrkN4UGvWFXdQAoiR_NXI9qrJALY,
  directives_Y6D8IjviQKB3EteSmgkh6nr8GGru_rC_DjhQ3lLa_GM,
  event_bus_ViUSoJuKW_GQ8BDIbjhUcCejLK_UaZMaFLZMM_zeOmo,
  pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ,
  vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk
]