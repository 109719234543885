import axios from 'axios'
import type { Language } from '~/types/Account'

const API_URL = window.origin + '/api'

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})
api.interceptors.request.use(function (config) {
  const securityStore = useSecurityStore()
  config.headers.Authorization = `Bearer ${securityStore.apiToken}`
  return config
})
api.interceptors.response.use(
  response => response,
  (error) => {
    const securityStore = useSecurityStore()
    if ((error.response && error.response.status === 401) && securityStore.isLoggedIn) {
      console.log('Logout')
    }
    return Promise.reject(error)
  }
)

export function findAvailableLanguages() {
  return api.get<Language[]>('/languages/available')
}
