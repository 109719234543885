<template>
  <component
    :is="props.component.value"
    v-if="component.visible"
    v-hasAccess="component.access"
    v-bind="component.props"
    @click="props.component.onClick"
  >
    <template #prepend>
      <v-icon v-if="component.props && component.props.prependIcon" :color="component.props.prependIconColor as string">
        {{ component.props.prependIcon }}
      </v-icon>
    </template>
    <div v-if="component.props">
      <v-icon v-if="component.props.icon" :icon="component.props.icon as string" />
      {{ component.props.text }}
    </div>
  </component>
</template>

<script lang="ts" setup>
import type { VIcon } from 'vuetify/components'
import type { ComponentData } from '~/types/GenericTable'

const props = defineProps<{
  component: ComponentData<Component>
}>()
</script>
