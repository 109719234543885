import { DateTime } from 'luxon'
import type { NestedKeyOf } from '~/types/GenericTable'

export function useTableDataFormatter() {
  const formatDateTimeField = (fieldValue: string | null) => {
    if (fieldValue) {
      return DateTime.fromISO(fieldValue).toFormat('dd-LL-yyyy HH:mm')
    }
    return ''
  }

  const getCommunicationTypeField = (fieldValue: Record<string, string>) => {
    return fieldValue['Type'] !== undefined ? fieldValue['Type'] : ''
  }

  const getCheckSumField = (contentChecksum: string) => {
    return contentChecksum.substring(0, 15)
  }

  const formatDateField = (fieldValue: string | null, zone?: string) => {
    if (fieldValue) {
      return DateTime.fromISO(fieldValue, { zone }).toFormat('dd-LL-yyyy')
    }
    return ''
  }

  const formatDateTimeFieldUTC = (fieldValue: string) => {
    return DateTime.fromISO(fieldValue, { zone: 'utc' }).setZone('system').toFormat('dd-LL-yyyy HH:mm')
  }

  function getNestedValue<T>(value: T, key: NestedKeyOf<T> | 'actions'): unknown {
    const nestedValue = key.split('.').reduce<unknown>((current, key) => {
      if (current && typeof current === 'object' && key in current) {
        return (current as Record<string, unknown>)[key]
      }
      return undefined
    }, value)
    return nestedValue
  }

  return {
    formatDateTimeField,
    getCommunicationTypeField,
    getCheckSumField,
    formatDateField,
    formatDateTimeFieldUTC,
    getNestedValue,
  }
}
