<template>
  <div>
    <v-tooltip :location="location==='top' ? 'top' : 'left'">
      <template #activator="{ props }">
        <div v-bind="props">
          <slot />
        </div>
      </template>
      <slot name="content">
        <span>{{ properties.text }}</span>
      </slot>
    </v-tooltip>
  </div>
</template>

<script lang="ts" setup>
import { VTooltip } from 'vuetify/components'
import type { TooltipStyleSettings } from '~/types/StyleSettings'

const properties = withDefaults(defineProps<TooltipStyleSettings>(), {
  text: '',
  location: 'top',
})
</script>
