<template>
  <v-row>
    <v-col :cols="12" class="py-0">
      <v-data-table
        v-model="selectedRows"
        v-model:sort-by.sync="pagination.sortBy"
        :headers="tableHeaders"
        :items="rows"
        :loading="loading"
        :search="pagination.searchHint"
        :items-per-page="pagination.pageLength"
        :page="pagination.pageNumber"
        :mobile="null"
        :show-select="selectStrategy!= undefined"
        :select-strategy="selectStrategy"
        density="compact"
        :multi-sort="multiSort"
        :item-value="rowIdentifier"
        hover
        hide-default-footer
        return-object
        class="devices-table"
      >
        <template #[`header.data-table-select`]="{ allSelected, someSelected }">
          <v-checkbox-btn
            v-if="selectStrategy==='all'"
            :indeterminate="someSelected && !allSelected"
            :model-value="allSelected"
            color="primary"
            @update:model-value="selectAllItems"
          />
          <div v-else class="pr-8">Access</div>
        </template>

        <template #[`item.data-table-select`]="{ internalItem, isSelected }">
          <v-checkbox-btn
            :model-value="isSelected(internalItem)"
            color="primary"
            @update:model-value="toggleSelectItem(internalItem.value, isSelected(internalItem))"
          />
        </template>
        <template v-for="(header, index) in tableHeaders" :key="index" #[header.itemSlot!]="{ item }">
          <span v-if="header.components == undefined">
            {{ header.formattedValue ? header.formattedValue(item) : getNestedValue(item, header.value as NestedKeyOf<T> | 'actions') }}
          </span>
          <div v-if="header.components">
            <div v-for="(component, i) in header.components(item)" :key="i" class="d-inline-flex">
              <reusable-simple-tooltip v-if="component.tooltip" :text="component.tooltip.text" :location="component.tooltip.location">
                <reusable-generic-component :component="component" />
              </reusable-simple-tooltip>
              <reusable-generic-component v-else :component="component" />
            </div>
          </div>
        </template>
      </v-data-table>
      <v-col cols="12" class="text-center pt-2">
        <v-pagination v-model="pagination.pageNumber" rounded :total-visible="5" :length="pageLength" color="primary" />
      </v-col>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup generic="T">
import { defineProps } from 'vue'
import type { Header, NestedKeyOf, PaginationSettings, TableSelectStrategy } from '~/types/GenericTable'

const { getNestedValue } = useTableDataFormatter()
const properties = withDefaults(defineProps<{
  loading?: boolean
  readOnly?: boolean
  headers: Header<T>[]
  selectStrategy?: TableSelectStrategy
  multiSort?: boolean
  rowIdentifier?: string
}>(), {
  loading: false,
  readOnly: false,
  multiSort: false,
})
const emit = defineEmits(['deselect-row', 'deselect-all'])
const pagination = defineModel<PaginationSettings>('pagination', { required: true })
const selectedRows = defineModel<T[]>('selectedRows', { required: false })
const rows = defineModel<T[]>('rows', { required: true })

const pageLength = computed(() => {
  return Math.ceil(rows.value.length / pagination.value.pageLength)
})

const tableHeaders = computed<Header<T>[]>(() => {
  return properties.headers.filter(h => h.visible)
})

const toggleSelectItem = (item: T, isSelected: boolean) => {
  if (isSelected) {
    emit('deselect-row', item)
  }
}

const selectAllItems = (select: boolean) => {
  if (!select) {
    emit('deselect-all')
  }
}
</script>

<style lang="css" scoped>
/* squeeze a little more space on desktop by removing the padding from some columns */
.devices-table >>> td:nth-child(even):not(.v-data-table__mobile-row),
.devices-table >>> th:nth-child(even):not(.v-data-table__mobile-row),
.devices-table >>> td:nth-child(1):not(.v-data-table__mobile-row),
.devices-table >>> th:nth-child(1):not(.v-data-table__mobile-row) {
  padding-left: 0px;
  padding-right: 0px;
}

.z-index-menu {
  z-index: 9;
}
</style>
