import mitt from 'mitt'
import type { ZodType } from 'zod'
import type { BufferTableHeader } from '~/types/Buffers'
import type { Header } from '~/types/GenericTable'

import type { Events, NotificationType, PromptCancellation, PromptConfirmation, PromptResponse } from '~/types/Reusable'

export default defineNuxtPlugin(() => {
  const emitter = mitt<Events>()
  const showNotification = (type: NotificationType, title: string) => {
    emitter.emit('show-notification', { type: type, timeout: 1500, title: title })
  }
  function createPromise<T>(id: string, cancellation: boolean = true) {
    const promise = new Promise<T>((resolve, reject) => {
      const handler = (event: PromptResponse<T>) => {
        if (event.id === id) {
          if (isPromptCancellation(event) && cancellation) {
            reject(event)
          } else if (isPromptConfirmation(event)) {
            resolve(event.value)
          }
          emitter.off('hide-prompt', handler)
        }
      }
      emitter.on('hide-prompt', handler)
    })
    return promise
  }
  function isPromptConfirmation<T>(event: PromptResponse<T>): event is PromptConfirmation<T> {
    return (<PromptConfirmation<T>> event).value !== undefined
  }
  function isPromptCancellation<T>(event: PromptResponse<T>): event is PromptCancellation<T> {
    return (<PromptCancellation<T>> event).cancelled !== undefined
  }
  function showInputPrompt<T extends string | number>(title: string, label: string, withCancellation: boolean = false, rules?: ZodType, initialValue?: T) {
    const id = crypto.randomUUID()
    const promise = createPromise<T>(id, withCancellation)
    emitter.emit('show-prompt', { type: 'text', id, label, title, rules, initialValue })
    return promise
  }
  function showSelectPrompt<T extends object | number | string, K = keyof T>(title: string, label: string, items: T[], itemTitle: K, rules?: ZodType, initialValue?: T | string) {
    const id = crypto.randomUUID()
    const promise = createPromise<T>(id)
    emitter.emit('show-prompt', { type: 'select', id, label, title, items, itemTitle, rules, initialValue })
    return promise
  }
  function showConfirmationPrompt(question: string) {
    const id = crypto.randomUUID()
    const promise = createPromise<boolean>(id, false)
    emitter.emit('show-prompt', { type: 'confirmation', id, question, title: '' })
    return promise
  }
  function showProgressPrompt(message: string) {
    const id = crypto.randomUUID()
    emitter.emit('show-prompt', { type: 'progress', id, message })
    return {
      resolve: () => {
        emitter.emit('hide-prompt', { id })
      },
    }
  }

  function showTableConfirmationPrompt<T>(title: string, question: string, rows: T[], headers: Header<BufferTableHeader>[]) {
    const id = crypto.randomUUID()
    const promise = createPromise<boolean>(id, false)
    emitter.emit('show-prompt', { type: 'confirmationTable', id, question, title, rows, headers })
    return promise
  }
  return {
    provide: {
      eventBus: emitter,
      notifications: {
        error: (title: string) => showNotification('error', title),
        success: (title: string) => showNotification('success', title),
        warning: (title: string) => showNotification('warning', title),
      },
      prompts: {
        input: showInputPrompt,
        select: showSelectPrompt,
        confirm: showConfirmationPrompt,
        dataConfirm: showTableConfirmationPrompt,
        progress: showProgressPrompt,
      },
    },
  }
})
