import { useWebSocket } from '@vueuse/core'
import { ReplaySubject, BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators'
import type { DeviceCommandNotificationEvent, DeviceStatusEvent, DeviceUpdateProgressEvent, EventType, RawDeviceMessageEvent, WebSocketEvent, XFSProgressEvent, XFSResponseEvent } from '~/types/WebSocketEventTypes'
// making sure it behaves as a singleton
let _instance: ReturnType<typeof createWebSocketComposable> | null = null
export function useWebSocketComposable() {
  if (_instance) return _instance
  _instance = createWebSocketComposable()
  return _instance
}

export function createWebSocketComposable() {
  type WebSocketOptions = {
    url: string
    apiKey?: string | null
    pingTimeout: number
    pongTimeout: number
    reconnectTimeout: number
    pingMessage: string
  }

  const options: WebSocketOptions = {
    url: window.origin + '/api/client-connect',
    pingTimeout: 1000 * 10,
    pongTimeout: 1000 * 10,
    reconnectTimeout: 1000 * 5,
    pingMessage: 'heartbeat',
  }

  const sessionId = ref<string>()
  const behaviorSubject = new BehaviorSubject<WebSocketEvent | null>(null)
  const sessionSubject = new ReplaySubject<string>(1)

  const { status, data, open, close } = useWebSocket<string>(getWebSocketEndpoint, {
    autoReconnect: {
      retries: 5,
      delay: options.reconnectTimeout,
      onFailed: () => {
        console.log('Failed to open WebSocket, realtime updates will not be received.')
      },
    },
    heartbeat: {
      message: options.pingMessage,
      interval: options.pingTimeout,
      pongTimeout: options.pongTimeout,
    },
    immediate: false,
  })

  function getWebSocketEndpoint() {
    if (options.apiKey) {
      const urlPartsRegex = /(.*):\/\/(.*)/g
      const matcher = urlPartsRegex.exec(options.url)
      let protocol = 'ws://'
      if (matcher) {
        if (matcher[1] === 'https') {
          protocol = 'wss://'
        }
        return `${protocol}${matcher[2]}?Authorization=${options.apiKey}`
      }
    }
  }

  function getSessionSubject() {
    return sessionSubject
  }

  function handleIncomingEvent(webSocketEvent: WebSocketEvent) {
    if (webSocketEvent.type === 'SessionId') {
      sessionId.value = webSocketEvent.payload.id
      sessionSubject.next(sessionId.value)
      console.log(`Session ID received ${sessionId.value}`)
    }
    if (webSocketEvent.type === 'XFSResponse') {
      console.log(webSocketEvent)
    }
    behaviorSubject.next(webSocketEvent)
  }

  function connect(apiKey: string) {
    options.apiKey = apiKey
    open()
    if (status.value === 'OPEN') {
      console.log('[WS] Connected')
    }
  }

  function disconnect() {
    close()
    console.log('[WS] Disconnected')
  }

  const getEventStream = <T extends WebSocketEvent>(eventType: EventType) =>
    behaviorSubject.pipe(filter((e): e is T => e?.type === eventType))

  function getDeviceCommandNotificationEventStream(hexacode: string) {
    return getEventStream<DeviceCommandNotificationEvent>('DeviceCommandNotification')
      .pipe(filter(e => e.payload.hexacode === hexacode))
  }

  function getDeviceStatusEventStream(hexacode: string) {
    return getEventStream<DeviceStatusEvent>('DeviceStatus')
      .pipe(filter(e => e.payload.hexacode === hexacode))
  }

  function getDeviceUpdateProgressEventStream(hexacode: string) {
    return getEventStream<DeviceUpdateProgressEvent>('DeviceUpdateProgress')
      .pipe(filter(e => e.payload.hexacode === hexacode))
  }

  function getDeviceRawMessageEventStream(hexacode: string) {
    return getEventStream<RawDeviceMessageEvent>('RawDeviceMessage')
      .pipe(filter(e => e.payload.hexacode === hexacode))
  }

  function getDeviceXFSResponseEventStream(hexacode: number) {
    return getEventStream<XFSResponseEvent>('XFSResponse')
      .pipe(filter(e => e.payload.hexacode === hexacode))
  }

  function getDeviceXFSProgressEventStream(hexacode: number) {
    return getEventStream<XFSProgressEvent>('XFSProgress')
      .pipe(filter(e => e.payload.hexacode === hexacode))
  }

  // Watch for incoming data
  watch(data, (received) => {
    if (received) {
      const parsedData: WebSocketEvent = JSON.parse(received)
      handleIncomingEvent(parsedData)
    }
  })

  return {
    sessionId,
    status,
    getSessionSubject,
    connect,
    disconnect,
    getEventStream,
    getDeviceCommandNotificationEventStream,
    getDeviceRawMessageEventStream,
    getDeviceXFSResponseEventStream,
    getDeviceXFSProgressEventStream,
    getDeviceUpdateProgressEventStream,
    getDeviceStatusEventStream,
  }
}
