import type { AccountPaginationRequest, AccountWithExclusionPaginationRequest } from '~/types/AccountSelectionView'
import type { AccessType, AccountDataSaveRequest, AccountSaveRequest, AccountSelection, AccountData, PersonalSettings, TwoFactorAuthenticationSetupKey } from '~/types/Account'
import type { AccountDirectLinkedDevice } from '~/types/Device'
import type { TablePaginationResponse } from '~/types/GenericTable'
import type { ApiToken, OptilinkApiToken } from '~/types/Security'
import { api } from '~/utils/backend'
import type { ExportSettings } from '~/types/ExportSettings'
import type { CronTriggerExecutionDate } from '~/components/reusable/CronGenerator.vue'

export function findAccountSettings() {
  return api.get<PersonalSettings>('/accounts/settings/get')
}

export function updateAccountSettings(settings: PersonalSettings) {
  return api.post<PersonalSettings>('/accounts/settings/save', settings)
}

export function setup2Fa() {
  return api.get<TwoFactorAuthenticationSetupKey>('/accounts/settings/2fa')
}

export function recoverPassword(email: string) {
  return api.put<never>('/accounts/recover-password', null, {
    params: {
      email: email,
    },
  })
}

export function verify2Fa(secret: string, code: string) {
  return api.post<never>('/api/v2/account/verify-2fa', secret, {
    params: {
      code: code,
    },
  })
}

export function getAccountsSelection() {
  return api.get<AccountSelection[]>('/accounts/accounts-selection')
}

export function paginateWithExclusion(paginationRequest: AccountWithExclusionPaginationRequest) {
  return api.post<TablePaginationResponse<AccountData>>('/accounts/paginate/exclude', paginationRequest)
}

export function paginateAccounts(paginationRequest: AccountPaginationRequest) {
  return api.post<TablePaginationResponse<AccountData>>('/accounts/paginate', paginationRequest)
}

export function findAvailableAccessType() {
  return api.get<AccessType[]>('/accounts/access-type')
}

export function deleteAccount(id: string) {
  return api.delete<never>(`/accounts/${id}/delete`)
}

export function lockAccount(id: string, message: string) {
  return api.put<never>(`/accounts/${id}/lock`, message)
}
export function unlockAccount(id: string) {
  return api.put<never>(`/accounts/${id}/unlock`)
}

export function getAccountSessions(id: string) {
  return api.get<ApiToken[]>(`/accounts/${id}/sessions`)
}

export function deleteAccountSession(session: string) {
  return api.delete<never>(`/accounts/sessions/${session}`)
}

export function saveAccountPermissions(id: string, permissions: AccountSaveRequest) {
  return api.put<never>(`/accounts/${id}/permissions`, permissions)
}

export function findDirectLinkedDevicesForAccount(id: string) {
  return api.get<AccountDirectLinkedDevice[]>('/accounts/direct-permissions', {
    params: {
      id: id,
    },
  })
}

export function getOptilinkApiTokens(accountId: string) {
  return api.get<OptilinkApiToken[]>('/accounts/optilink-api-tokens', { params: { accountId: accountId } })
}

export function deleteOptilinkApiToken(tokenId: string) {
  return api.delete<never>('/accounts/optilink-api-tokens', {
    params: {
      id: tokenId,
    },
  })
}

export function saveOptilinkApiToken(token: OptilinkApiToken) {
  return api.put<OptilinkApiToken>('/accounts/optilink-api-tokens', token)
}

export function validateAccountUsername(id: string | null, username: string) {
  return api.get('/accounts/validate/username', {
    params: {
      id: id,
      name: username,
    },
  })
}

export function saveAccount(accountData: AccountDataSaveRequest) {
  return api.post('/accounts/save', accountData)
}

export function findAccountById(id: string) {
  return api.get<AccountData>(`/accounts/${id}/get`)
}

export function findExportSettings(id: string) {
  return api.get<ExportSettings>(`/accounts/${id}/export/settings`)
}

export function saveExportSettings(id: string, exportSettings: ExportSettings) {
  return api.post(`/accounts/${id}/export/settings`, exportSettings)
}

export function getCronNextExecutionDates(count: number, expression: string) {
  return api.get<CronTriggerExecutionDate[]>('/accounts/export/cron-execution-dates', {
    params: {
      count: count,
      expression: expression,
    },
  })
}

export function startExport(id: string, type: string) {
  return api.post(`/accounts/${id}/export/start`, null, {
    params: {
      type: type,
    },
  })
}
