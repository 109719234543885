import { defineStore } from 'pinia'
import { useLocalStorage, StorageSerializers } from '@vueuse/core'
import type { LoginResponse } from '~/types/Login'
import { AVAILABLE_GRANTS, GrantPriority, type ApiToken, type ApiTokenMetadata, type Grant, type GrantType, type Grants, type LoginAsApiToken, type Permissions } from '~/types/Security'

export const useSecurityStore = defineStore('Security', () => {
  const { connect, disconnect, status } = useWebSocketComposable()
  const grants = computed<Grants>(() => {
    const values = AVAILABLE_GRANTS.reduce((a, c) => {
      a[c] = 'DENY'
      return a
    }, {} as Grants)
    currentUser.value?.authorities.reduce((p, c) => {
      const authority = c.authority.replace('GRANT_', '') as Grant
      p[authority] = c.type
      return p
    }, values)
    return values
  })
  const permissions = computed<Permissions>(() => {
    return AVAILABLE_GRANTS.reduce((a, c) => {
      a[c] = {
        DENY: () => hasAccess(c, 'DENY'),
        READ: () => hasAccess(c, 'READ'),
        WRITE: () => hasAccess(c, 'WRITE'),
      }
      return a
    }, {} as Permissions)
  })
  const roles = computed(() => {
    if (!currentUser.value) {
      return []
    }
    return currentUser.value.authorities.map(a => a.authority)
  })

  const availablePcNumbers = computed<number[]>(() => {
    let pcNumbers = currentUserMetadata.value?.pcNumbers
    if (permissions.value.APPLY_ACL.WRITE()) {
      pcNumbers = Array.from({ length: 99 }, (value, index) => index + 1)
    }
    return pcNumbers
  })

  const username = computed(() => currentUserMetadata.value?.username)
  const isLoggedIn = computed(() => !!currentUser.value)
  const isLoggedInAs = computed(() => currentUser.value && (currentUser.value as LoginAsApiToken).source !== undefined)
  const apiToken = computed(() => currentUser.value?.id)
  const currentUser = useLocalStorage<ApiToken>('management-current-user', null, { serializer: StorageSerializers.object })
  const currentUserMetadata = useLocalStorage<ApiTokenMetadata>('management-current-user-meta', null, { serializer: StorageSerializers.object })

  const load = (data: LoginResponse) => {
    currentUser.value = data.token
    currentUserMetadata.value = data.metadata
  }

  const unload = () => {
    currentUser.value = null
    currentUserMetadata.value = null
  }

  const hasAccess = (grant: Grant, requiredType: GrantType) => {
    let result = false
    const type = grants.value[grant]
    if (!type) {
      result = false
    } else if (GrantPriority[type] < GrantPriority[requiredType]) {
      result = false
    } else {
      result = true
    }
    return result
  }

  watch(apiToken, (newValue) => {
    if (newValue && status.value === 'CLOSED') {
      connect(newValue)
    } else if (!newValue && status.value === 'OPEN') {
      disconnect()
    }
  }, { immediate: true })

  return {
    apiToken,
    isLoggedInAs,
    isLoggedIn,
    roles,
    username,
    permissions,
    load,
    unload,
    hasAccess,
    availablePcNumbers,
  }
})
