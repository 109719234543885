import type { DirectiveBinding } from 'vue'
import { defineNuxtPlugin } from '#app'

const removeChildIfMatched = (el: HTMLElement, binding: DirectiveBinding) => {
  let access = false
  if (typeof binding.value === 'boolean') {
    access = binding.value
  } else if (typeof binding.value === 'function') {
    access = binding.value()
  }
  if (!access && el.parentNode !== null) {
    el.parentNode.removeChild(el)
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('hasAccess', {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
      removeChildIfMatched(el, binding)
    },
    updated(el: HTMLElement, binding: DirectiveBinding) {
      removeChildIfMatched(el, binding)
    },
  })
})
