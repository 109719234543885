import { StorageSerializers } from '@vueuse/core'
import { defineStore } from 'pinia'
import type { PersonalSettings } from '~/types/Account'
import { findAccountSettings, updateAccountSettings } from '~/utils/backend/accounts-api'

export const useSettingsStore = defineStore('Settings', () => {
  const defaultSettings: PersonalSettings = {
    username: '',
    email: null,
    use2FA: false,
    accountSettings: {
      defaultPcNumber: 1,
      language: {
        id: null,
        code: 'EN',
        label: 'English',
      },
      dateFormat: 'DD-MM-YYYY HH:mm',
    },
  }
  const securityStore = useSecurityStore()
  const { isLoggedIn } = storeToRefs(securityStore)
  const request = ref(Promise.resolve(defaultSettings))
  const controller = useAsyncState(() => findAccountSettings().then(r => r.data), defaultSettings, { immediate: false })
  const settings = controller.state
  const isLoading = controller.isLoading
  const themeUtil = useTheme()
  const theme = useLocalStorage<string>('theme', 'light', { serializer: StorageSerializers.string })

  function toggleTheme(input: boolean) {
    if (input) {
      theme.value = 'dark'
    } else {
      theme.value = 'light'
    }
    themeUtil.global.name.value = theme.value
  }

  function load() {
    themeUtil.global.name.value = theme.value
    if (!isLoading.value) {
      request.value = controller.execute()
    }
  }

  function unload() {
    request.value = Promise.resolve(defaultSettings)
    settings.value = { ...defaultSettings }
  }

  function saveAccountSettings(body: PersonalSettings): Promise<PersonalSettings> {
    return updateAccountSettings(body).then(r => settings.value = r.data)
  }

  whenever(isLoggedIn, load, { immediate: true })
  whenever(() => !isLoggedIn.value, unload)

  return {
    toggleTheme,
    settings,
    isLoading,
    request,
    load,
    unload,
    saveAccountSettings,
  }
})
