
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as access_45groupsT0XXrRpmrs_MJ49GAecfhsCtcVAJnxm0lhL5TMzUVmMMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/access-groups.vue?macro=true";
import { default as accountsHgheUMoLkawAO1kBFsBrd9sn23Jwlx7EnwgGu8AjYR0Meta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/accounts.vue?macro=true";
import { default as adf_45management7C1asYKkf1Tz0U7dxx6PkJVdX0c2_45QoalyapRp1_MecMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/adf-management.vue?macro=true";
import { default as buffers3byH2qpQw_BIPDtQ4nGhYxJf4ILOcOJaJCN7ZAEeeSMMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/buffers.vue?macro=true";
import { default as dashboard011EuZaXZqK9bnjsK8Xs0Id5R2hLuguDGndMCrRGGIcMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/dashboard.vue?macro=true";
import { default as dealers_45and_45subdealershMjXantEa98_Kmi8U8uQX0E4f_45k8gnf0J1_45WuHUUcjQMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/dealers-and-subdealers.vue?macro=true";
import { default as device_45control_45panelTeutraEjRgtu7Ja8qKN5c7HHRh16rCmvlX6flJcpV40Meta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/device-control-panel.vue?macro=true";
import { default as devicessH4AaNikY7jIvTMpmRevu2cKiticGBe_45652_45Su5lH4oMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/devices.vue?macro=true";
import { default as domainsUS9UIQmiwAV0sUKWmtp8YwjSLW3wpLjnzVZkBmM_sIYMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/domains.vue?macro=true";
import { default as export_45monitornnsKRVGGXdRIumL0nAtEcrRfOL22iyhPWbXJF_45Hav8MMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/export-monitor.vue?macro=true";
import { default as export_45templatesCutRLYVzczuygPDX1HkAf_ttW_45kZp_902KJdQf6n6NoMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/export-templates.vue?macro=true";
import { default as forgot_45passwordPcM3C8umk8BGBYM0GIWkLhGXAcmQ_45iCTxszxb1KE3vkMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/forgot-password.vue?macro=true";
import { default as ftp_45userszC_1MYJeNXIFgqPklzsBQs8cypwAWcRrOI2mdPQ31OoMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/ftp-users.vue?macro=true";
import { default as export_45templatessZcL_5mEuOaIpltZQfdQBqvffekVngYYGbPtQQqY0yMMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/help/export-templates.vue?macro=true";
import { default as indexr91gvxyZY3YH5DqVLAbRbmfDYSBdMrfD9sIJ_45bAkOicMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/index.vue?macro=true";
import { default as key_45pair_45managementQP7qXVkgmX6gU_451MV5_45THgIZ_mQFcDFlUFk5i6qIO00Meta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/key-pair-management.vue?macro=true";
import { default as Loginhxp0oINA4LDVADG3oTe9rCvIcK1lGIqd0SVn8eVIpnUMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/Login.vue?macro=true";
import { default as mail_45usersqm664XTxnRRf5DCTGQ4jOzAVqOFxHip5VJsh9IgGce8Meta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/mail-users.vue?macro=true";
import { default as policiesFbMkPDpRB9LuVh7NnnMJ6Cx8Uc9tgWAZVuWsDFdAPgoMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/security/policies.vue?macro=true";
import { default as rolesCN_Rp81HnxWPpHXX0Xmvn4MseVE2uuA04kmV_nuUO1UMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/security/roles.vue?macro=true";
import { default as securitykVNG4lgHQz3Y5_WgYh8dFEtzrYxPyqkuyc4YMVUPJoEMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/security.vue?macro=true";
import { default as software_45versions5DxMWZiXGaoZiBSzw5XeKXs2nUJ1TOYUNZgcsRLBdkYMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/software-versions.vue?macro=true";
import { default as storyblok9Y3MofsUEkTx5G7EX7jrGWIuCNWwhRRG6s6G0kA5z0sMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/storyblok.vue?macro=true";
import { default as subscription_45planspL7LjsLMVDHfP7B_45RxaNgdHXfzhFo1C_45eaDC9WOXufIMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/subscription-plans.vue?macro=true";
import { default as bug_45reportsgSgWaT9slpKU3TpW_e_45zGKPnTXtg2Z01W33PGDTmJ0cMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/support/bug-reports.vue?macro=true";
import { default as feature_45requestsqadAYolYl8e310dLBsxlbuh_45z8y1ZJGnbjyur5IpFQEMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/support/feature-requests.vue?macro=true";
import { default as supportQ6a3ZPNvwzbJirEYyam9lZFsQbjFg__Ra_sXumiaW1EMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/support.vue?macro=true";
import { default as virtual_45devices_45management8kTsiKuo0bE66lLPWZFxPIVI2iNzvO_c_450GaehC0qJEMeta } from "/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/virtual-devices-management.vue?macro=true";
export default [
  {
    name: "access-groups",
    path: "/access-groups",
    meta: access_45groupsT0XXrRpmrs_MJ49GAecfhsCtcVAJnxm0lhL5TMzUVmMMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/access-groups.vue")
  },
  {
    name: "accounts",
    path: "/accounts",
    meta: accountsHgheUMoLkawAO1kBFsBrd9sn23Jwlx7EnwgGu8AjYR0Meta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/accounts.vue")
  },
  {
    name: "adf-management",
    path: "/adf-management",
    meta: adf_45management7C1asYKkf1Tz0U7dxx6PkJVdX0c2_45QoalyapRp1_MecMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/adf-management.vue")
  },
  {
    name: "buffers",
    path: "/buffers",
    meta: buffers3byH2qpQw_BIPDtQ4nGhYxJf4ILOcOJaJCN7ZAEeeSMMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/buffers.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboard011EuZaXZqK9bnjsK8Xs0Id5R2hLuguDGndMCrRGGIcMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/dashboard.vue")
  },
  {
    name: "dealers-and-subdealers",
    path: "/dealers-and-subdealers",
    meta: dealers_45and_45subdealershMjXantEa98_Kmi8U8uQX0E4f_45k8gnf0J1_45WuHUUcjQMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/dealers-and-subdealers.vue")
  },
  {
    name: "device-control-panel",
    path: "/device-control-panel",
    meta: device_45control_45panelTeutraEjRgtu7Ja8qKN5c7HHRh16rCmvlX6flJcpV40Meta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/device-control-panel.vue")
  },
  {
    name: "devices",
    path: "/devices",
    meta: devicessH4AaNikY7jIvTMpmRevu2cKiticGBe_45652_45Su5lH4oMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/devices.vue")
  },
  {
    name: "domains",
    path: "/domains",
    meta: domainsUS9UIQmiwAV0sUKWmtp8YwjSLW3wpLjnzVZkBmM_sIYMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/domains.vue")
  },
  {
    name: "export-monitor",
    path: "/export-monitor",
    meta: export_45monitornnsKRVGGXdRIumL0nAtEcrRfOL22iyhPWbXJF_45Hav8MMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/export-monitor.vue")
  },
  {
    name: "export-templates",
    path: "/export-templates",
    meta: export_45templatesCutRLYVzczuygPDX1HkAf_ttW_45kZp_902KJdQf6n6NoMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/export-templates.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/forgot-password.vue")
  },
  {
    name: "ftp-users",
    path: "/ftp-users",
    meta: ftp_45userszC_1MYJeNXIFgqPklzsBQs8cypwAWcRrOI2mdPQ31OoMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/ftp-users.vue")
  },
  {
    name: "help-export-templates",
    path: "/help/export-templates",
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/help/export-templates.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexr91gvxyZY3YH5DqVLAbRbmfDYSBdMrfD9sIJ_45bAkOicMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/index.vue")
  },
  {
    name: "key-pair-management",
    path: "/key-pair-management",
    meta: key_45pair_45managementQP7qXVkgmX6gU_451MV5_45THgIZ_mQFcDFlUFk5i6qIO00Meta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/key-pair-management.vue")
  },
  {
    name: "Login",
    path: "/Login",
    meta: Loginhxp0oINA4LDVADG3oTe9rCvIcK1lGIqd0SVn8eVIpnUMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/Login.vue")
  },
  {
    name: "mail-users",
    path: "/mail-users",
    meta: mail_45usersqm664XTxnRRf5DCTGQ4jOzAVqOFxHip5VJsh9IgGce8Meta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/mail-users.vue")
  },
  {
    name: "security",
    path: "/security",
    meta: securitykVNG4lgHQz3Y5_WgYh8dFEtzrYxPyqkuyc4YMVUPJoEMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/security.vue"),
    children: [
  {
    name: "security-policies",
    path: "policies",
    meta: policiesFbMkPDpRB9LuVh7NnnMJ6Cx8Uc9tgWAZVuWsDFdAPgoMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/security/policies.vue")
  },
  {
    name: "security-roles",
    path: "roles",
    meta: rolesCN_Rp81HnxWPpHXX0Xmvn4MseVE2uuA04kmV_nuUO1UMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/security/roles.vue")
  }
]
  },
  {
    name: "software-versions",
    path: "/software-versions",
    meta: software_45versions5DxMWZiXGaoZiBSzw5XeKXs2nUJ1TOYUNZgcsRLBdkYMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/software-versions.vue")
  },
  {
    name: "storyblok",
    path: "/storyblok",
    meta: storyblok9Y3MofsUEkTx5G7EX7jrGWIuCNWwhRRG6s6G0kA5z0sMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/storyblok.vue")
  },
  {
    name: "subscription-plans",
    path: "/subscription-plans",
    meta: subscription_45planspL7LjsLMVDHfP7B_45RxaNgdHXfzhFo1C_45eaDC9WOXufIMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/subscription-plans.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: supportQ6a3ZPNvwzbJirEYyam9lZFsQbjFg__Ra_sXumiaW1EMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/support.vue"),
    children: [
  {
    name: "support-bug-reports",
    path: "bug-reports",
    meta: bug_45reportsgSgWaT9slpKU3TpW_e_45zGKPnTXtg2Z01W33PGDTmJ0cMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/support/bug-reports.vue")
  },
  {
    name: "support-feature-requests",
    path: "feature-requests",
    meta: feature_45requestsqadAYolYl8e310dLBsxlbuh_45z8y1ZJGnbjyur5IpFQEMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/support/feature-requests.vue")
  }
]
  },
  {
    name: "virtual-devices-management",
    path: "/virtual-devices-management",
    meta: virtual_45devices_45management8kTsiKuo0bE66lLPWZFxPIVI2iNzvO_c_450GaehC0qJEMeta || {},
    component: () => import("/home/optilink/data/jenkins/workspace/management-tool-frontend_develop/pages/virtual-devices-management.vue")
  }
]