import { mdiAlertCircle, mdiAlertCircleOutline, mdiAlertDecagramOutline, mdiAlertOutline, mdiBellRingOutline, mdiCheckCircleOutline, mdiFileArrowLeftRightOutline, mdiFileReplaceOutline, mdiFileSign, mdiFileSyncOutline, mdiFileUploadOutline, mdiInformationOutline, mdiLicense, mdiNetworkStrength1Alert, mdiWifiCheck, mdiWifiRemove } from '@mdi/js'
import type { DeviceStatus, DeviceTagMessage } from '~/types/Device'
import type { BufferHeaderReportStatus, ExportLevel, ExportStatus, ExportType, Operation, OptimaBoxExportStatus } from '~/types/ExportMonitor'
import type { VeeValidationVuetifyConfig } from '~/types/VeeValidationVuetifyConfig'
import type { StyleSettings } from '~/types/StyleSettings'

export const VUETIFY_INPUT_CONFIG = (state: VeeValidationVuetifyConfig) => {
  return {
    props: {
      'error-messages': state.errors,
      'base-color': state.valid ? 'success' : '',
      'color': state.valid ? 'success' : 'error',
    },
  }
}
export const ExportTypeSettings: Record<ExportType, Partial<StyleSettings>> = {
  FTP_ALARM: {
    color: 'red',
    text: 'FTP alarms export',
    variant: 'outlined',
  },
  FTP_BUFFERS: {
    color: 'primary',
    text: 'FTP buffers export',
    variant: 'outlined',
  },
  FTP_OPTIMA_BOX: {
    color: 'green',
    text: 'FTP optimabox export',
    variant: 'outlined',
  },
  MAIL_ALARM: {
    color: 'warning',
    text: 'Mail alarms export',
    variant: 'outlined',
  },
  MAIL_BUFFERS: {
    color: 'info',
    text: 'Mail buffers export',
    variant: 'outlined',
  },
}
export const ExportStatusSettings: Record<ExportStatus, Partial<StyleSettings>> = {
  SUCCESS: {
    text: 'Successfull',
    color: 'success',
    variant: 'outlined',
  },
  PARTIALLY_SUCCESFULL: {
    text: 'Partially succesfull',
    color: 'orange',
    variant: 'outlined',
  },
  FAILED: {
    text: 'Failed',
    color: 'red',
    variant: 'outlined',
  },
}
export const ExportLevelSettings: Record<ExportLevel, Partial<StyleSettings>> = {
  WARN: {
    color: 'warning',
  },
  INFO: {
    color: 'info',
  },
  ERROR: {
    color: 'error',
  },
}
export const ExportDetailsSettings: Record<ExportStatus, Partial<StyleSettings>> = {
  SUCCESS: {
    text: 'Successfull',
    color: 'success',
    variant: 'outlined',
  },
  PARTIALLY_SUCCESFULL: {
    text: 'Partially succesfull',
    color: 'orange',
    variant: 'outlined',
  },
  FAILED: {
    text: 'Failed',
    color: 'red',
    variant: 'outlined',
  },
}

export const DeviceTag: Record<DeviceTagMessage, Partial<StyleSettings>> = {
  MISSING_LICENSE: {
    color: 'red',
    icon: mdiLicense,
    variant: 'flat',
  },
  BAD_CONNECTION: {
    color: 'red',
    icon: mdiNetworkStrength1Alert,
    variant: 'flat',
  },
  WARN: {
    color: 'orange',
    icon: mdiAlertOutline,
    variant: 'flat',
  },
  ERROR: {
    color: 'pink',
    icon: mdiAlertDecagramOutline,
    variant: 'flat',
  },
  INFO: {
    color: 'primary',
    icon: mdiAlertCircle,
    variant: 'flat',
  },
}

export const Status: Record<DeviceStatus, Partial<StyleSettings>> = {
  ON: {
    color: 'green',
    icon: mdiWifiCheck,
    variant: 'text',
  },
  ON_ALARM: {
    color: 'red',
    icon: mdiBellRingOutline,
    variant: 'text',
  },
  OFF: {
    color: 'grey',
    icon: mdiWifiRemove,
    variant: 'text',
  },
  OFF_ALARM: {
    color: 'grey',
    icon: mdiBellRingOutline,
    variant: 'text',
  },
}

export const BufferHeaderExportStatus: Record<BufferHeaderReportStatus, Partial<StyleSettings>> = {
  OK: {
    icon: mdiCheckCircleOutline,
    color: 'green',
    variant: 'plain',
  },
  NO_VALUES: {
    icon: mdiInformationOutline,
    color: 'grey',
    variant: 'plain',
  },
  NOT_FOUND: {
    icon: mdiAlertCircleOutline,
    color: 'warning',
    variant: 'plain',
  },
  ERROR: {
    icon: mdiAlertDecagramOutline,
    color: 'error',
    variant: 'plain',
  },
  INVALID: {
    icon: mdiAlertCircleOutline,
    color: 'error',
    variant: 'plain',
  },
}

export const ExportOperation: Record<Operation, Partial<StyleSettings>> = {
  CREATE: {
    color: 'green',
    text: 'Create',
    variant: 'elevated',
  },
  GET: {
    color: 'grey',
    text: 'Get',
    variant: 'elevated',
  },
  DELETE: {
    color: 'red',
    text: 'Delete',
    variant: 'elevated',
  },
}

export const OptimaboxExportStatusSettings: Record<OptimaBoxExportStatus, Partial<StyleSettings>> = {
  COLLECTION_FAILED: {
    icon: mdiFileSyncOutline,
    tooltipText: 'Data collected',

  },
  EXPORT_FAILED: {
    icon: mdiFileUploadOutline,
    tooltipText: 'Data exported',

  },
  CONFIRMATION_FAILED: {
    icon: mdiFileArrowLeftRightOutline,
    tooltipText: 'Data confirmed back',

  },
  CONFIGURATION_FAILED: {
    icon: mdiFileReplaceOutline,
    tooltipText: 'Configuration available',

  },
  WRITE_FAILED: {
    icon: mdiFileSign,
    tooltipText: 'Configuration written',

  },
  CONFIGURATION_NOT_AVAILABLE: {

  },
  SUCCESS: {
    color: 'green',

  },
}
