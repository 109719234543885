<template>
  <NuxtLayout>
    <NuxtPwaManifest />
    <NuxtPage />
    <reusable-notification v-if="notificationConfig" v-model="showNotification" :config="notificationConfig" />
    <reusable-prompt v-for="prompt in promptQueue" :key="prompt.id" v-bind="prompt" />
  </NuxtLayout>
</template>

<script lang="ts" setup>
import type { NotificationConfig, PromptConfig } from './types/Reusable'

useSecurityStore()
useSettingsStore()
const { $eventBus } = useNuxtApp()
const notificationConfig = ref<NotificationConfig>()
const showNotification = ref(false)
const promptQueue = ref<PromptConfig<Record<string, never>>[]>([])
$eventBus.on('show-prompt', (event) => {
  promptQueue.value.push(event)
})
$eventBus.on('hide-prompt', () => {
  promptQueue.value.shift()
})
$eventBus.on('show-notification', (event) => {
  showNotification.value = false
  nextTick(() => {
    notificationConfig.value = event
    showNotification.value = true
  })
})
$eventBus.on('hide-notification', () => {
  showNotification.value = false
})
</script>
